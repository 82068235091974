<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */

import { computed, ref } from "vue";
import { DEFAULT_RULES, useFormValidator } from "@/composables/utils/formValidator";
import { required, sameAs } from "@vuelidate/validators";
import { reqAxios } from "@/composables/core/func";
import { useMainStore } from "@/store/main/index";
import { useBuildingStore } from "@/store/building";
import { useRoute, useRouter } from "vue-router";
import { useRecaptcha } from "@/composables/core/recaptcha";
import { sendEvent } from "@/composables/utils/sendEvent";

import PhoneInput from "@/components/regularComponents/UIComponents/PhoneInput.vue";
import Recaptcha from "@/components/regularComponents/UIComponents/Recaptcha.vue";
import Checked from "@/components/regularComponents/UIComponents/SVG/popup/Checked.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";
import Input from "@/components/regularComponents/FormComponents/Input.vue";
import X from "@/components/regularComponents/UIComponents/SVG/popup/X.vue";

const props = withDefaults(
  defineProps<{
    hideCheckbox?: boolean;
    title?: string;
    formType?: string;
    noteText?: string;
    emailRequired?: boolean;
    dontPushQuery?: boolean;
    eventFormType?: string;
  }>(),
  {
    formType: "flatBooking",
    title: "LEAVE THE COORDINATES AND WE WILL CONTACT YOU",
  }
);

const emit = defineEmits<{
  (e: "close"): void;
  (e: "update", items: { isSubmited: boolean; isSuccess: boolean }): void;
}>();

const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();
const buildingStore = useBuildingStore();

const formData = ref({
  name: null,
  phone: null,
  email: null,
  privacy: props.hideCheckbox ? true : null,
});
const dial = ref(null);
const isRecaptchaSolved = ref(false);
const isSubmited = ref(false);
const isRequestFinished = ref(false);
const isSuccess = ref(false);
const showStep2 = ref(false);
const recaptchaKey = ref(0);

const rules = {
  ...DEFAULT_RULES,
  privacy: { required, checked: sameAs(true) },
};

// @ts-ignore
rules.email = {};

const { isFormValid, errors, doValidation, resetState } = useFormValidator({
  formData,
  rules,
});

const isProjectPage = computed(
  () =>
    route.params?.slugs?.[0] === "projects" && Boolean(route.params?.slugs?.[1]?.length)
);

const bookableFlatsIds = computed(() => buildingStore?.selectedFlatsForBooking || []);

const onClose = () => {
  emit("close");
  isSubmited.value = false;
  showStep2.value = false;

  const hasQuery = route.query.hasOwnProperty(mainStore.autoOpenModalQueryKey);
  if (hasQuery) {
    router.replace({
      query: {
        ...route.query,
        [mainStore.autoOpenModalQueryKey]: undefined,
      },
    });

    setTimeout(() => {
      mainStore.hasIgnorableQuery = false;
    }, 800);
  }
};

const onSubmit = async () => {
  doValidation(true);
  const token = await useRecaptcha();

  // if (!isFormValid.value || !isRecaptchaSolved.value) return;
  if (!isFormValid.value || !token) return;

  isSubmited.value = true;

  const params: any = {
    formType: buildingStore?.selectedFlatsForBooking?.length
      ? "flatBooking"
      : props.formType,
    ...formData.value,
    phone: `+${dial.value}${formData.value.phone}`,
  };

  if (isProjectPage.value) {
    params.flat = buildingStore.selectedFlat?.flat_number;
    params.floor = buildingStore.selectedFloor?.floor_number;
    params.project = buildingStore.selectedProject?.title;
    params.projectId = buildingStore.selectedProject?.id;
    params.area = buildingStore.selectedFlat?.area_m2;
  }

  if (buildingStore?.selectedBlock) {
    params.block = buildingStore.selectedBlock?.title;
  }

  if (!params.flat && buildingStore?.selectedFlatsForBooking?.length) {
    params.flat = buildingStore.selectedFlatsForBooking
      .map((el) => el.flatNumber)
      .join(",");
  }

  const response = await reqAxios("main/saveSubmitedForm", params);

  isSuccess.value = response?.data?.[0] === "success";

  if (isSuccess.value && props.eventFormType) {
    const eventPayload: any = { formType: props.eventFormType };

    if (props.eventFormType == "Send request") {
      eventPayload.projectType = buildingStore.selectedProject?.slug;
    }

    sendEvent("form-submission", eventPayload);
  }

  setTimeout(() => {
    isRequestFinished.value = true;
    showStep2.value = true;
    resetState();
    recaptchaKey.value++;
    isRecaptchaSolved.value = false;

    if (isSuccess.value) {
      if (!props.dontPushQuery) {
        // sendEvent("?thankyou");

        // if ((route.params?.slugs as string[])?.some(x => x?.includes('flat-'))) {
        //   if (buildingStore.selectedProject?.slug == "montemar") {
        //     sendEvent("Montemar - Request Submission");
        //   }

        //   if (buildingStore.selectedProject?.slug == "portline") {
        //     sendEvent("Portline - Request Submission");
        //   }

        //   if (buildingStore.selectedProject?.slug == "bulvar-point") {
        //     sendEvent("Boulevard point - Request Submission");
        //   }

        //   if (buildingStore.selectedProject?.slug == "midtown") {
        //     sendEvent("Midtown- Request Submission");
        //   }
        // } else {
        //   sendEvent("Get more information Popup - Form submission");
        // }

        mainStore.hasIgnorableQuery = true;
        router.push({
          query: {
            ...route.query,
            [mainStore.autoOpenModalQueryKey]: null,
          },
        });
      }
    }
  }, 500);

  setTimeout(() => {
    isRequestFinished.value = false;
    isSubmited.value = false;
  }, 2000);
};
</script>

<template>
  <div>
    <div class="after-submit" v-if="showStep2">
      <div class="after-submit__icon">
        <Checked v-if="isSuccess" class="j-center" />
        <X v-else class="j-center" />
      </div>

      <p class="after-submit__title">
        {{ $t(isSuccess ? "thank you!" : "sorry!") }}
      </p>
      <p class="after-submit__teaser">
        <template v-if="isSuccess">
          {{ $t("your meeting with gumbati consultant was successfully scheduled") }}
        </template>
        <template v-else>
          {{
            $t(
              "your meeting with gumbati consultant was not scheduled. please try again!"
            )
          }}
        </template>
      </p>

      <Button full @click="onClose" btn-text="done" />
    </div>

    <form class="book-call-form" v-else>
      <h4 class="book-call-title uppercase">
        {{ $t(title?.toLowerCase()) }}
      </h4>

      <div class="selected-flats mb-16" v-if="bookableFlatsIds.length">
        <p class="selected-flats__title p-16">{{ $t("selected flats") }}</p>
        <ul class="selected-flats__items d-flex wrp-wrap">
          <li
            class="selected-flats__items-item p-16"
            v-for="(item, index) in bookableFlatsIds"
            :key="index"
          >
            №{{ item?.flatNumber }}
            <X
              class="selected-flats__items-item--close"
              @click="buildingStore.toggleSelectedFlatsForBooking(item)"
            />
          </li>
        </ul>
      </div>

      <Input
        label="Name"
        required
        class="form-item"
        v-model="formData.name"
        :errorText="errors?.name"
      />
      <PhoneInput
        label="Phone Number"
        required
        class="form-item"
        v-model:dial="dial"
        v-model="formData.phone"
        :errorText="errors?.phone"
      />
      <!-- <Input
        label="E-Mail Address"
        class="form-item form-item--email"
        v-model="formData.email"
        type="email"
        :required="emailRequired"
        :errorText="errors?.email"
      /> -->
      <div
        :class="[
          'd-flex form-item al-center form-item-privacy',
          { 'form-item--privacy-error': errors?.privacy },
        ]"
      >
        <input type="checkbox" id="privacy" v-model="(formData.privacy as any)" />
        <label for="privacy" class="privacy-label">
          {{ $t("i consent to the processing of my personal data according to the") }}
          <router-link :to="`/${mainStore.lang}/privacy-policy`" class="underline">{{
            $t("privacy policy [book-call]")
          }}</router-link>

          <sup class="custom-input__input-label--required op-6">*</sup>
        </label>
      </div>

      <div class="mb-16 form-item-recaptcha">
        <Recaptcha :key="recaptchaKey" />
      </div>
      <Button
        full
        @click.prevent="onSubmit"
        btn-text="send request"
        :isLoading="!isRequestFinished && isSubmited"
        :is-done="isRequestFinished"
      />
    </form>
    <p class="book-call__note" v-if="noteText && !showStep2">
      {{ $t(noteText?.toLowerCase()) }}
    </p>
  </div>
</template>

<style lang="scss">
.book-call {
  &-title {
    padding: 24px 0;
    @include weight(700);

    font-size: 36px;

    @include maxq(desktop-2k) {
      font-size: 24px;
    }

    @include maxq(desktop-lg) {
      width: 65%;
      font-size: 20px;
      line-height: 24px;
    }

    @include maxq(mobile) {
      width: 100%;
    }
  }
  &-form {
    .form-item {
      margin-bottom: 16px;
    }

    input[type="checkbox"] {
      accent-color: $modalDark;
      margin-right: 12px;
      flex-shrink: 0;

      width: 40px;
      height: 40px;

      @include maxq(desktop-2k) {
        width: 32px;
        height: 32px;
      }

      @include maxq(desktop-lg) {
        width: 24px;
        height: 24px;
      }
      border: 1px solid $silver;
    }

    .privacy-label {
      font-size: 21px;
      line-height: 1;

      @include maxq(desktop-lg) {
        font-size: 16px;
      }
    }

    .form-item {
      &--privacy-error {
        color: $border-error;
      }
    }

    .selected-flats {
      margin-bottom: 24px;

      &__title {
        font-size: 2em;
        margin-bottom: 16px;

        @include maxq(desktop-2k) {
          font-size: 1.5em;
        }

        @include maxq(desktop-lg) {
          font-size: 1em;
          margin-bottom: 8px;
        }
      }

      &__items {
        gap: 13px;
        &-item {
          border: 1px solid $border-hover;
          position: relative;

          font-size: 2em;
          padding: 0.5em;

          @include maxq(desktop-2k) {
            padding: 0.3em;
            font-size: 1.4em;
          }

          @include maxq(desktop-lg) {
            padding: 4px 8px;
            font-size: 1em;
          }

          &--close {
            cursor: pointer;
            position: absolute;
            top: -20px;
            right: -10px;
            border-radius: 50%;
            background-color: $black;
            --w: 40px;

            @include maxq(desktop-2k) {
              top: -15px;
              --w: 28px;
            }

            @include maxq(desktop-lg) {
              top: -10px;
              --w: 20px;
            }

            svg {
              width: var(--w);
              height: var(--w);
              padding: 4px;
              path {
                stroke: #fff;
                stroke-width: 10px;
              }
            }
          }
        }
      }
    }
  }

  &__note {
    color: #1a1a1a;
    opacity: 0.6;
    font-size: 12px;
    line-height: 18px;
    margin-top: 24px;
  }
}

.after-submit {
  text-align: center;
  &__icon {
    margin: 0 auto;
    margin-top: 40px;
  }
  &__title {
    @include weight(700);
    font-size: 20px;
    line-height: 24px;
    padding: 8px 0;
  }

  &__teaser {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 40px;
  }
}

@include locale {
  .book-call-form .privacy-label {
    line-height: 1.2;
    font-size: 14px;
  }
}
</style>
